<template>
  <validation-observer ref="simpleRules">
    <b-card-code
      :title="$route.name == 'detail-kejadian-penting' ? 'Form Detail Kejadian Penting' : 'Form Edit Kejadian Penting'"
      :class="$route.name == 'detail-kejadian-penting' ? 'detail-dispo' : '' "
    >
      <b-button
        v-show="$route.name === 'detail-kejadian-penting' && authorize"
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        style="position: absolute;right: 15px;top: -10px;"
        type="submit"
        @click.prevent="goEdit()"
      >
        <span class="align-middle">Edit Kejadian Penting</span>
      </b-button>
      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Perihal"
            rules="required"
          >
            <b-form-group
              label="Perihal"
              label-for="Perihal"
            >
              <b-form-input
                id="Perihal"
                v-model="Perihal"
                placeholder="Input Perihal"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Kategori"
            label-for="Kategori"
          >
            <v-select
              v-model="Kategori"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :disabled="$route.name == 'detail-kejadian-penting'"
              placeholder="Pilih Kategori"
              :options="optionsKategori"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal Mulai"
            rules="required"
          >
            <b-form-group
              label="Tanggal Mulai"
              label-for="Tanggal Mulai"
            >
              <b-form-datepicker
                id="date-datepicker"
                v-model="date"
                locale="id"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal Selesai"
            rules="required"
          >
            <b-form-group
              label="Tanggal Selesai"
              label-for="Tanggal Selesai"
            >
              <b-form-datepicker
                id="end-datepicker"
                v-model="date_end"
                locale="id"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <h5 class="titlerow2">
            File Kejadian Penting
          </h5>
          <b-form-group label-for="FileSurat">
            <b-row
              v-if="gantiFile == false && file !== null"
              class="match-height"
            >
              <b-col
                v-for="item in file"
                :key="item.access_url"
                md="12"
                class="fileSurat"
              >
                <div
                  class="open-file"
                  @click="openFile(item.access_url)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="56"
                  />
                  <h5 class="ml-1">
                    Open File
                    <span> {{ item.real_filename }}{{ item.ext }} </span>
                  </h5>
                </div>
              </b-col>

              <b-button
                v-show="$route.name === 'edit-kejadian-penting'"
                v-model="gantiFile"
                variant="outline-primary"
                class="bg-gradient-primary mt-1 ml-1"
                style="height: 29px"
                size="sm"
                @click.prevent="toggleFile"
              >
                <span class="align-middle">Ganti File</span>
              </b-button>
            </b-row>

            <b-row v-else>
              <b-col md="12">
                <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;">
                  <b-form-file
                    id="FileSurat"
                    ref="file"
                    v-model="file"
                    type="file"
                    placeholder="Input File Surat"
                    drop-placeholder="Drop file here..."
                    multiple="multiple"
                    @change="fileChange"
                  />

                  <b-button
                    v-show="file !== null"
                    v-model="gantiFile"
                    size="sm"
                    variant="outline-primary"
                    class="bg-gradient-primary mt-1"
                    @click.prevent="toggleFile"
                  >
                    <span class="align-middle">Open File</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tag"
            rules="required"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >
              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <label for="textarea-default">Deskripsi</label>
          <b-form-textarea
            id="textarea-default"
            v-model="deskripsi"
            placeholder="Textarea"
            rows="3"
          />
        </b-col>

        <b-col md="6">
          <label for="textarea-default">Catatan</label>
          <b-form-textarea
            id="textarea-default"
            v-model="catatan"
            placeholder="Textarea"
            rows="3"
          />
        </b-col>
      </b-row>

      <b-button
        v-if="$route.name !== 'detail-kejadian-penting'"
        variant="outline-primary"
        class="bg-gradient-primary"
        type="submit"
        @click.prevent="validationForm"
      >
        <span class="align-middle">Simpan</span>
      </b-button>
    </b-card-code>

    <b-row class="match-height">
      <b-col md="6">
        <b-card-code
          title="Riwayat Kejadian Penting"
          class="activity"
        >
          <app-timeline
            v-for="data in activity"
            :key="data.id"
          >
            <app-timeline-item
              :title="data.user.name"
              :subtitle="data.message"
              :time="dateFormat(data.updated_at)"
              variant="info"
              style="padding-bottom:20px"
            />
          </app-timeline>
        </b-card-code>
      </b-col>
    </b-row>

  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  VBModal,
  BFormCheckboxGroup,
  BFormTextarea,
  BInputGroup,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from '@axios'
import vSelect from 'vue-select'
import store from '@/store/index'
import dayjs from 'dayjs'
// import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    vSelect,
    BFormDatepicker,
    AppTimeline,
    AppTimelineItem,
    BInputGroup,
    VBModal,
  },
  data() {
    return {
      authorize: false,
      authorizeResponder: false,
      Kategori: '',
      date_end: null,
      gantiFile: false,
      date: null,
      userRole: '',
      complain: '',
      NoSurat: '',
      code: '',
      contact: '',
      title: '',
      group: '',
      Perihal: '',
      deskripsiPenyelesaian: '',
      optionsKategori: [
        { value: 'transaksi', text: 'Transaksi' },
        { value: 'NonTransaksi', text: 'Non Transaksi' },
        { value: 'layanan', text: 'Layanan' },
        { value: 'produk', text: 'Produk' },
        { value: 'after selling', text: 'After Selling' },
        { value: 'distribusi', text: 'Distribusi' },
      ],
      AllData: [],
      file: [],
      optionsResponder: [],
      optionsKeputusan: [],
      selectedPerintah: [],
      selectedPerintahFinal: [],
      optionsViewers: [{ value: '', text: '' }],
      selectedViewers: [],
      selectedKeputusan: [],
      activity: [],
      tags: [],
      fileID: [],
    }
  },
  created() {
    this.authorize = store.state.userData.data.is_admin === 1
    this.userRole = store.state.userData.data.role.name
  },
  mounted() {
    this.getDetail()
    // this.getResponder()
    // this.getViewer()
    // this.getDecision()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.editSurat()
        }
      })
    },

    toggleFile() {
      if (this.gantiFile === false) {
        this.gantiFile = true
      } else {
        this.gantiFile = false
      }
    },

    async getDetail() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(`/siap/event/detail/${param}`)
      this.code = data.ref_num
      this.Perihal = data.title
      this.deskripsi = data.desc
      this.catatan = data.note
      this.Kategori = data.category.name
      this.date = data.from_date
      this.date_end = data.to_date
      this.file = data.file
      this.NoSurat = data.ref_num
      this.tags = data.tag.map(e => e.name)
      this.getActivity()
    },

    openFile(e) {
      window.open(e, '_blank')
    },

    async changeFile() {
      this.editFile = !this.editFile
      const param = Number(this.$route.params.id)
      const { data } = await axios.get(`siap/outgoing/${param}`)
      this.file = data.file
    },

    goEdit() {
      const param = this.$route.params.pathMatch
      window.location.href = `/kejadian-penting/edit/${param}`
    },

    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'Complaint',
        },
      })
      this.file = data.data
    },

    async editSurat() {
      const param = this.$route.params.pathMatch
      await axios
        .post(`siap/event/update/${param}`, {
          cat: this.Kategori,
          title: this.Perihal,
          from_date: dayjs(this.date).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          to_date: dayjs(this.date_end).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          desc: this.deskripsi,
          note: this.catatan,
          file: this.file,
          tags: this.tags,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDetail()
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    dateFormat(time) {
      const formatted = dayjs(time).format('DD/MM/YYYY (HH:mm)')
      if (formatted === 'Invalid Date') {
        return '-'
      }
      return formatted
    },

    async getActivity() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(`siap/event/activity/${param}`)
      this.activity = data.data
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}

.detail-dispo {
  .form-control,
  .custom-select {
    border: unset;
    border-bottom: 1px solid #d8d6de;
    border-radius: 0;
    pointer-events: none;
  }
}

.fileSurat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #a6a6a6;
    display: block;
    margin-top: 5px;
    font-size: 12px;
  }
}

.tanggapan {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .komentar-title {
    display: flex;
    align-items: baseline;
    span {
      font-size: 13px;
      margin-left: 10px;
    }
  }
  .komentar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .left {
      flex-grow: 1;
      margin-right: 10px;
      span {
        font-size: 14px;
        display: block;
        flex-grow: 1;
        margin-right: 10px;
      }
    }
  }
  .uncomment {
    input {
      border: unset !important;
      // border-left: solid 1px !important;
      border-radius: 0 !important;
      padding-left: 0;
      background: #fff;
      color: #5e5873;
    }
  }
  input {
    border: 1px solid #d8d6de !important;
    pointer-events: unset !important;
  }
  .avatar {
    width: 80px;
    img {
      width: 100%;
      height: intrinsic;
    }
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
  }
  .input {
    margin-left: 25px;
    flex-grow: 1;
  }
  .input-group-append {
    cursor: pointer;
  }
  .input-group-text {
    background-image: linear-gradient(47deg, #00427a, #005ead);
    color: #fff;
  }
  .btn {
    height: 38px;
  }
}

[dir] .vs--disabled .vs__selected {
  background-color: #00427a;
  color: #fff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #615f6f;
}

.open-file {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
  transition: background-color 0.5s ease;
  &:hover {
    background: #efefef;
  }
}
.form-group {
  position: relative;
}
.warning {
  font-size: 11px;
  color: orange;
  position: absolute;
  top: 0;
  right: 15px;
}

.activity {
  .card-body {
    max-height: 400px;
    overflow-y: scroll;
  }
}
.titlerow2 {
  width: 100%;
  font-size: 12px;
}
</style>
